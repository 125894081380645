import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: NoT FoUND" />
    <div className="flex justify-center flex-col text-sm font-bold uppercase p-4 text-center">
      <p>nothing to see here</p>
    </div>
  </Layout>
);

export default NotFoundPage;
